(function () {
'use strict';

/**
 * @ngdoc object
 * @name register.controller:RegisterCtrl
 *
 * @description
 *
 */
angular
    .module('register')
    .controller('RegisterCtrl', RegisterCtrl);

function RegisterCtrl($stateParams, $state, $location, RCRUsers, Events, Alerts) {
  var vm = this;
  vm.ctrlName = 'RegisterCtrl';

  if (RCRUsers.isAuthenticated()) {
    $location.path('/home');
  }

  vm.templateState = 'form';
  if($stateParams.usersid != 'new'){
    RCRUsers.verifyUser({"id":atob($stateParams.usersid), "emailVerified":1}, function(result) {
		vm.templateState = 'verified';

		Events.create({
			"eventType": "verifyEmail",
			"eventData": result,
			"userId": atob($stateParams.usersid)
		});
    });
	Alerts.addAlert('ok',"We've successfully verified your email, you can now log in.");
    $state.go("login");
  }

  vm.createUser = function (userDetails, registerForm) {

    userDetails.username = userDetails.email;
    userDetails.userType = 1;
    userDetails.emailVerified = 0;
    userDetails.activated = 1;

    userDetails.dateCreated = Math.round(new Date().getTime() / 1000);

    RCRUsers.addUser(userDetails, function (response) {

      if(response.name && response.name == "ValidationError") {
        Events.create({
			"eventType":"create user", 
			"eventData": response, 
			"userId":response.id
		});
        vm.success = false;
        registerForm.email.$error.validation = true;
		Alerts.addAlert('error','Failed validation');
      }else {
        vm.templateState = 'registered';
		Alerts.addAlert('ok','Successful registration');
      }
	  



    });


  };

}
}());
